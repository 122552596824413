import api from '../axios'

const resource = '/api/locum/files'

export default {
    getMyDocuments: (filterQuery) => api.get(`${resource}?${filterQuery}`),

    saveUploadedFileKey: (payload) => api.post('api/locum/files', payload),
    downloadOrViewFile: (id) => api.get(`api/locum/files/${id}`),
    deleteFileFile: (id) => api.delete(`api/locum/files/${id}`)
}
