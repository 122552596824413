<template>
  <div>
    <b-modal
        id="modal-center"
        v-model="show"
        centered
        hide-footer
        no-close-on-backdrop
        size="sm"
        title=""
    >
      <b-overlay
          :show="loading"
          rounded="sm"
      >
        <h3 class="text-center font-weight-700">Edit Document</h3>
        <div class="m-1 p-1" style="background-color: #EFF7FC">
          <svg fill="none" height="24" viewBox="0 0 29 24" width="29" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
              <path
                  d="M26.0253 3.49377H13.8675C13.2231 3.49257 12.5856 3.36081 11.9934 3.10645C11.4013 2.85209 10.8668 2.4804 10.4223 2.01381L9.82725 1.38848C9.41005 0.95128 8.9086 0.60315 8.35314 0.365099C7.79769 0.127047 7.19976 0.00401186 6.59545 0.00341797H2.71061C1.99237 0.00409569 1.30369 0.289543 0.795575 0.79718C0.287457 1.30482 0.00135768 1.99322 0 2.71147L0 7.24112C0.219499 7.02004 0.480603 6.84461 0.768248 6.72496C1.05589 6.60531 1.36438 6.5438 1.67592 6.54398H27.0594C27.371 6.5438 27.6795 6.60531 27.9671 6.72496C28.2548 6.84461 28.5159 7.02004 28.7354 7.24112V6.20438C28.7347 5.48578 28.449 4.79679 27.9409 4.28862C27.4328 3.78044 26.7439 3.49458 26.0253 3.49377Z"
                  fill="#98CAE6"/>
              <path
                  d="M27.0594 7.2334H1.67592C1.23152 7.23394 0.805478 7.41076 0.491285 7.72505C0.177092 8.03934 0.000407254 8.46543 0 8.90983L0 22.3244C0.000543008 22.7687 0.177287 23.1947 0.491466 23.5088C0.805645 23.823 1.23161 23.9998 1.67592 24.0003H27.0594C27.5038 23.9998 27.9297 23.823 28.2439 23.5088C28.5581 23.1947 28.7348 22.7687 28.7354 22.3244V8.90983C28.735 8.46543 28.5583 8.03934 28.2441 7.72505C27.9299 7.41076 27.5038 7.23394 27.0594 7.2334Z"
                  fill="#BADDF0"/>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect fill="white" height="24" width="28.7354"/>
              </clipPath>
            </defs>
          </svg>
          <span class="ml-1 font-weight-bold">Mandatory Documents</span>


        </div>
        <div class="p-1">
          <validation-observer ref="validateForm" v-slot="{ valid }">
            <b-form
                ref="form"
            >
              <validation-provider
                  v-slot="{ errors }"
                  :bails="false"
                  name="input"
                  rules="required"
              >
                <b-form-file
                    id="input"
                    ref="fileInput"
                    :placeholder="makeUpperCase(document.type)"
                    class="mb-1"
                    drop-placeholder="Drop file here..."
                    no-drop
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form>
          </validation-observer>
        </div>

        <b-button class="btn-block" variant="primary" @click="editDocument()">Save</b-button>

      </b-overlay>

    </b-modal>
  </div>
</template>

<script>
import FileUploadMixin from '@/mixins/FileUploadMixin'
import {BButton, BForm, BFormFile, BModal, BOverlay} from 'bootstrap-vue'
import SuccessMessage from '@/mixins/SuccessMessage'
import ErrorMessages from '@/mixins/ErrorMessages'

export default {
  name: "editDocumentModal",
  components: {
    BButton,
    BModal,
    BFormFile,
    BOverlay,
    BForm
  },
  mixins: [FileUploadMixin, ErrorMessages, SuccessMessage],
  data() {
    return {
      document: {},
      show: false,
      loading: false,
    }
  },
  methods: {
    open(document) {
      this.document = document
      this.show = true
    },
    async editDocument() {
      if (!(this.$refs.validateForm.validate())) return
      this.loading = true
      try {
        await this.uploadFile(this.document.type, this.$refs.fileInput.files[0])
        setTimeout(() => {
          this.showSuccessMessage(`${this.makeUpperCase(this.document.type)} updated successfully.`)
          this.$emit('updated')
          this.show = false
          this.loading = false
        }, 15000)

      } catch (err) {
        this.convertAndNotifyError(err)
        this.loading = false
      }
    },
  }
}

</script>

<style scoped>

</style>
