<template>
  <div>
    <b-modal
        id="modal-center"
        v-model="show"
        centered
        hide-footer
        no-close-on-backdrop
        size="lg"
        title=""
    >
      <b-overlay
          :show="loading"
          rounded="sm"
      >
        <h3 class="text-center font-weight-700">Add Document</h3>
        <div class="m-1 p-1" style="background-color: #EFF7FC">
          <svg fill="none" height="24" viewBox="0 0 29 24" width="29" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
              <path
                  d="M26.0253 3.49377H13.8675C13.2231 3.49257 12.5856 3.36081 11.9934 3.10645C11.4013 2.85209 10.8668 2.4804 10.4223 2.01381L9.82725 1.38848C9.41005 0.95128 8.9086 0.60315 8.35314 0.365099C7.79769 0.127047 7.19976 0.00401186 6.59545 0.00341797H2.71061C1.99237 0.00409569 1.30369 0.289543 0.795575 0.79718C0.287457 1.30482 0.00135768 1.99322 0 2.71147L0 7.24112C0.219499 7.02004 0.480603 6.84461 0.768248 6.72496C1.05589 6.60531 1.36438 6.5438 1.67592 6.54398H27.0594C27.371 6.5438 27.6795 6.60531 27.9671 6.72496C28.2548 6.84461 28.5159 7.02004 28.7354 7.24112V6.20438C28.7347 5.48578 28.449 4.79679 27.9409 4.28862C27.4328 3.78044 26.7439 3.49458 26.0253 3.49377Z"
                  fill="#98CAE6"/>
              <path
                  d="M27.0594 7.2334H1.67592C1.23152 7.23394 0.805478 7.41076 0.491285 7.72505C0.177092 8.03934 0.000407254 8.46543 0 8.90983L0 22.3244C0.000543008 22.7687 0.177287 23.1947 0.491466 23.5088C0.805645 23.823 1.23161 23.9998 1.67592 24.0003H27.0594C27.5038 23.9998 27.9297 23.823 28.2439 23.5088C28.5581 23.1947 28.7348 22.7687 28.7354 22.3244V8.90983C28.735 8.46543 28.5583 8.03934 28.2441 7.72505C27.9299 7.41076 27.5038 7.23394 27.0594 7.2334Z"
                  fill="#BADDF0"/>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect fill="white" height="24" width="28.7354"/>
              </clipPath>
            </defs>
          </svg>
          <span class="font-weight-bold ml-1">Mandatory Documents</span>
        </div>
        <div class="p-1">
          <b-form-file
              ref="file_cv"
              class="mb-1"
              drop-placeholder="Drop file here..."
              no-drop
              placeholder="CV"
          />
          <b-form-file
              ref="file_cct"
              class="mb-1"
              drop-placeholder="Drop file here..."
              no-drop
              placeholder="CCT"
          />
          <b-form-file
              ref="file_passport"
              class="mb-1"
              drop-placeholder="Drop file here..."
              no-drop
              placeholder="Passport"
          />
          <b-form-file
              ref="file_bds"
              drop-placeholder="Drop file here..."
              no-drop
              placeholder="DBS"
          />

        </div>
        <div class="p-1 m-1" style="background-color: #EFF7FC">
          <svg fill="none" height="24" viewBox="0 0 29 24" width="29" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
              <path
                  d="M26.0253 3.49377H13.8675C13.2231 3.49257 12.5856 3.36081 11.9934 3.10645C11.4013 2.85209 10.8668 2.4804 10.4223 2.01381L9.82725 1.38848C9.41005 0.95128 8.9086 0.60315 8.35314 0.365099C7.79769 0.127047 7.19976 0.00401186 6.59545 0.00341797H2.71061C1.99237 0.00409569 1.30369 0.289543 0.795575 0.79718C0.287457 1.30482 0.00135768 1.99322 0 2.71147L0 7.24112C0.219499 7.02004 0.480603 6.84461 0.768248 6.72496C1.05589 6.60531 1.36438 6.5438 1.67592 6.54398H27.0594C27.371 6.5438 27.6795 6.60531 27.9671 6.72496C28.2548 6.84461 28.5159 7.02004 28.7354 7.24112V6.20438C28.7347 5.48578 28.449 4.79679 27.9409 4.28862C27.4328 3.78044 26.7439 3.49458 26.0253 3.49377Z"
                  fill="#98CAE6"/>
              <path
                  d="M27.0594 7.2334H1.67592C1.23152 7.23394 0.805478 7.41076 0.491285 7.72505C0.177092 8.03934 0.000407254 8.46543 0 8.90983L0 22.3244C0.000543008 22.7687 0.177287 23.1947 0.491466 23.5088C0.805645 23.823 1.23161 23.9998 1.67592 24.0003H27.0594C27.5038 23.9998 27.9297 23.823 28.2439 23.5088C28.5581 23.1947 28.7348 22.7687 28.7354 22.3244V8.90983C28.735 8.46543 28.5583 8.03934 28.2441 7.72505C27.9299 7.41076 27.5038 7.23394 27.0594 7.2334Z"
                  fill="#BADDF0"/>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect fill="white" height="24" width="28.7354"/>
              </clipPath>
            </defs>
          </svg>
          <span class="font-weight-bold ml-1">Additional Documents</span>


        </div>
        <div class="p-1">
          <b-form-file
              ref="file_immunisations"
              class="mb-1"
              drop-placeholder="Drop file here..."
              no-drop
              placeholder="Immunisations"
          />
          <b-form-file
              ref="file_references"
              class="mb-1"
              drop-placeholder="Drop file here..."
              no-drop
              placeholder="References"
          />
          <b-form-file
              ref="file_safeguarding_training_children"
              class="mb-1"
              drop-placeholder="Drop file here..."
              no-drop
              placeholder="Safeguarding Training Children"
          />
          <b-form-file
              ref="file_safeguarding_training_adults"
              drop-placeholder="Drop file here..."
              no-drop
              placeholder="Safeguarding Training Adults"
          />

        </div>
        <b-button class="btn-block " style="margin:4px" variant="primary" @click="uploadFiles()"> save</b-button>

      </b-overlay>

    </b-modal>
  </div>
</template>

<script>
import {BButton, BForm, BFormFile, BModal, BOverlay} from 'bootstrap-vue'
import FileUploadMixin from "@/mixins/FileUploadMixin";
import ErrorMessages from "@/mixins/ErrorMessages";
import SuccessMessage from "@/mixins/SuccessMessage";

export default {
  name: "addDocumentModal",
  components: {
    BButton,
    BModal,
    BFormFile,
    BOverlay,
    BForm
  },
  mixins: [FileUploadMixin, ErrorMessages, SuccessMessage],
  data() {
    return {
      document: {},
      show: false,
      loading: false,
      fileUploaded: false,
    }
  },
  methods: {
    open() {
      this.show = true
    },
    async uploadFiles() {

      this.loading = true
      try {
        this.fileUploadFunction().then(() => {
          if (this.fileUploaded) {
            setTimeout(() => {
              this.$emit('updated')
              this.showSuccessMessage(` Documents were added.`)
              this.show = false
              this.loading = false
              this.fileUploaded = false
            }, 15000)
          }
        })
      } catch (err) {
        this.convertAndNotifyError(err)
        this.loading = false
      }
    },

    async fileUploadFunction() {
      let count = 0
      if (this.$refs.file_cv.files[0]) {
        count++
        await this.uploadFile('cv', this.$refs.file_cv.files[0])
      }
      if (this.$refs.file_cct.files[0]) {
        count++
        await this.uploadFile('cct', this.$refs.file_cct.files[0])
      }
      if (this.$refs.file_passport.files[0]) {
        count++
        await this.uploadFile('passport', this.$refs.file_passport.files[0])
      }
      if (this.$refs.file_bds.files[0]) {
        count++
        await this.uploadFile('bds', this.$refs.file_bds.files[0])
      }

      if (this.$refs.file_immunisations.files[0]) {
        count++
        await this.uploadFile('immunisations', this.$refs.file_immunisations.files[0])
      }
      if (this.$refs.file_references.files[0]) {
        count++
        await this.uploadFile('references', this.$refs.file_references.files[0])
      }
      if (this.$refs.file_safeguarding_training_children.files[0]) {
        count++
        await this.uploadFile('safeguarding training children', this.$refs.file_safeguarding_training_children.files[0])
      }
      if (this.$refs.file_safeguarding_training_adults.files[0]) {
        count++
        await this.uploadFile('safeguarding training adults', this.$refs.file_safeguarding_training_adults.files[0])
      }
      this.fileUploaded = true
    }
  }
}
</script>

<style scoped>

</style>
