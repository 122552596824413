<template>
  <div>

    <b-card no-body>
      <div>
        <b-row>
          <b-col class="" cols="6" md="4" sm="12" xs="12">
            <b-form-group>
              <b-input-group class="input-group-merge ml-1 mt-1">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon"/>
                </b-input-group-prepend>
                <b-form-input
                    id="icons-search"
                    placeholder="Search"
                    v-model="filters['search']"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class=" " cols="6" md="8" sm="12" xs="12">
            <b-button
                class="shadow-sm  mt-1 mr-1  float-right"
                size=""
                variant="primary" @click="$refs.addDocumentModal.open()">
              <span>+ Add Document</span>
            </b-button>

          </b-col>
          <b-col class=" " cols="12">
            <b-alert
                v-height-fade.appear
                :show="currentUser ? !currentUser.locum.mandatory_files_status : false"
                class="mb-0 mx-1"
                variant="warning"
            >
              <div class="alert-body text-dark">
                <feather-icon
                    class="mr-50"
                    icon="InfoIcon"
                />
                To apply for shifts you need to upload the following mandatory documents: <b
                  class=" font-weight-700">CV</b>,
                <b class=" font-weight-700">Passport</b>, <b class=" font-weight-700">CCT</b> and <b
                  class=" font-weight-700">DBS</b>.
              </div>
            </b-alert>

          </b-col>
        </b-row>

        <b-row class="p-1">

          <b-col cols="12">
            <b-overlay
                :show="tableLoading"
                rounded="sm"
            >

              <b-table
                  ref="table"
                  :current-page="currentPage"
                  :fields="fields"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :items="getMyDocuments"
                  :per-page="pagination.perPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  class="mobile_table_css"
                  hover
                  responsive
                  show-empty
              >

                >

                <!-- Name $ Avatar -->

                <template #cell(type)="data"

                >
                  <b-col>
                    <b-media no-body>
                      <b-img
                          :src="require(`@/assets/images/filetypes/${data.item.mime_type.split('/')[1]}.png`)"
                          alt="browser img"
                          class="mr-1 "
                          height="30"
                      />
                      <h6 class="align-self-center my-auto font-weight-bolder">
                        {{ makeUpperCase(data.item.type) }}
                      </h6>
                    </b-media>
                  </b-col>
                </template>

                <template #cell(created_at)="data">
                  {{ momentFormat(data.item.created_at, 'dddd DD - MM - YYYY') }}
                </template>


                <template #cell(action)="data">
                  <b-button
                      class="btn-icon bg-white text-primary m-1"
                      size="23"
                      variant="outline-white"
                      @click="downloadFile(data.item)"
                  >
                    <feather-icon icon="DownloadIcon" size="18"/>
                  </b-button>

                  <b-button
                      class=" bg-white text-danger m-1"
                      size="23"
                      variant="outline-white"
                      @click="deleteFile(data.item.id)"
                  >
                    <feather-icon icon="TrashIcon" size="18" variant="outline-danger"/>
                  </b-button>


                </template>
                <template #cell(state)="data">
                  <b-badge :variant="getStatusWithColor(data.item.state).variant"
                           style="padding:8px">
                    <span class="">  {{ makeUpperCase(data.item.state) }} </span>
                  </b-badge>
                </template>

                <!-- Column: Actions -->
                <template #cell(action)="data">
                  <b-dropdown
                      no-caret
                      variant="link"
                  >

                    <template #button-content>
                      <feather-icon
                          class="align-middle text-body"
                          icon="MoreVerticalIcon"
                          size="16"
                      />
                    </template>
                    <b-dropdown-item @click="$refs.editDocumentModal.open(data.item)">
                      <feather-icon icon="EditIcon"/>
                      <span class="align-middle ml-50">Edit</span>
                    </b-dropdown-item>

                    <b-dropdown-item @click="downloadDocument(data.item)">
                      <feather-icon icon="DownloadIcon"/>
                      <span class="align-middle ml-50">Download</span>
                    </b-dropdown-item>

                    <!--                    <b-dropdown-item @click="printDocument(data.item.id)">
                                          <feather-icon icon="PrinterIcon"/>
                                          <span class="align-middle ml-50">Print</span>
                                        </b-dropdown-item>-->
                    <b-dropdown-item @click="confirmDelete(data.item)">
                      <feather-icon icon="TrashIcon"/>
                      <span class="align-middle ml-50">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </b-overlay>
          </b-col>

        </b-row>
      </div>
    </b-card>


    <!-- Modals -->
    <EditDocumentModal ref="editDocumentModal" @updated="dataLoad"/>
    <AddDocumentModal ref="addDocumentModal" @updated="dataLoad"/>
  </div>

</template>

<script>
import {
  BAlert,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BLink,
  BListGroup,
  BListGroupItem,
  BMedia,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTable,
  VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import MomentMixin from '@/mixins/MomentMixin'
import axios from 'axios'
import Ripple from "vue-ripple-directive";
import DocumentsApi from '@/apis/modules/Documents'
import EditDocumentModal from "@/views/documents/modals/editDocumentModal";
import AddDocumentModal from "@/views/documents/modals/addDocumentModal";
import {mapGetters} from 'vuex'
import {heightFade} from '@core/directives/animations'
import {apiUrl} from '@/constants/config'
import Filter from '@/mixins/FilterMixin'

export default {
  name: 'index',
  components: {
    EditDocumentModal,
    AddDocumentModal,
    BAlert,
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
    BCardBody,
    BCardHeader,
    BFormCheckbox,
    BImg,
    BMedia,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    vSelect,
    BModal,
    BFormFile,
    BOverlay,

    VBTooltip
  },
  mixins: [MomentMixin, Filter],
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
    'height-fade': heightFade,
  },

  data() {
    return {
      config: {},
      buttonLoading: false,
      tableLoading: false,
      noDataTable: '',
      uploadedFile: null,
      showFileUploadModal: false,
      modalLoading: false,
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows: '',
        from: '',
        to: ''
      },
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filters: {},
      filter : null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'type',
          label: 'DOCUMENT NAME',
          sortable: false
        },
        {
          key: 'created_at',
          label: 'date uploaded',
          sortable: false
        },

        {
          key: 'state',
          label: 'Status',

        }, {
          key: 'action',
          label: 'action',

        },


      ],
      documents: []
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  async mounted() {
  },
  methods: {
    filterQueryUpdate () {
      this.$refs.table.refresh()
    },
    async downloadDocument(file) {
      this.tableLoading = true
      try {
        axios.get(`${apiUrl}/locum/files/${file.id}`, {
          responseType: 'arraybuffer',
          withCredentials: true
        }).then(response => {
          let blob = new Blob([response.data], {type: file.type}),
              link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = file.name
          link.click()
          this.showSuccessMessage(`${this.makeUpperCase(file.type)} downloaded successfully.`)
          this.tableLoading = false
        })
      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    async printDocument($file_id) {

    },
    async confirmDelete(file) {
      this.$swal({
        title: 'Are you sure you want to delete this document?',
        text: "Document will be permanently deleted. This action cannot be reverted.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete',
        cancelButtonText: 'No, go back',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteDocument(file)
        }
      })
    },
    async deleteDocument(file) {
      try {
        await DocumentsApi.deleteFileFile(file.id)
        this.showSuccessMessage(`${this.makeUpperCase(file.type)} deleted successfully.`)
        this.$refs.table.refresh()
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    async dataLoad(){
      await this.$refs.table.refresh()
    },

    async getMyDocuments() {
      try {
        this.tableLoading = true
        // this.documents = (await DocumentsApi.getMyDocuments(this.filterQuery)).data.data

        const Response = (await DocumentsApi.getMyDocuments(this.filterQuery)).data.data.map((x) => ({
          created_at: x.created_at,
          created_by: x.created_by,
          id: x.id,
          locum_id: x.locum_id,
          mime_type: x.mime_type,
          name: x.name,
          path: x.path,
          s3_key: x.s3_key,
          size: x.size,
          state: x.state,
          type: x.type,
          updated_at: x.updated_at,
          updated_by: x.updated_by
        }))
        this.tableLoading = false
        return Response


      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    async downloadFile(item) {
      try {
        this.tableLoading = true;

        // const response = await staffBank.downloadImage(item.id)
        axios
            .get(`https://hyre.locumboxapp.com/api/mp/salaried-staff/files/${item.id}/download?auth_practice_id=${sessionStorage.getItem('practice_id')}`, {
              // headers{
              // }
              responseType: 'arraybuffer',
              withCredentials: true
            })
            .then(response => {

              let blob = new Blob([response.data], {type: 'application/pdf'}),
                  // url = window.URL.createObjectURL(blob)
                  // let blob = new Blob([response.data], { type: 'application/pdf' })
                  link = document.createElement('a')
              link.href = window.URL.createObjectURL(blob)
              link.download = item.name
              link.click()

            })

        this.showSuccessMessage('File download successfully')
        this.$refs.table.refresh()
      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    async deleteFile(id) {
      try {
        this.tableLoading = true
        await staffBank.deleteToFile(id)
        this.tableLoading = false
        this.$refs.table.refresh()
      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    async uploadFile() {
      try {
        this.buttonLoading = true
        fileUploader.store(this.uploadedFile, {
          progress: progress => {
            this.uploadProgress = Math.round(progress * 100)
          },
          'visibility': 'public-read',
          // baseURL: 'http://hyre-be-v2.test/api/mp',
          baseURL: `${baseApiUrl}`,

          params: {
            'auth_practice_id': `${sessionStorage.getItem('practice_id')}`
          }
        }).then(async response => {
          const key = response.key
          await this.setImageKeyToApi(key)
          this.$refs.table.refresh()
        })

      } catch (error) {
        this.convertAndNotifyError(error)


      }


    },
    async setImageKeyToApi(key) {

      (await staffBank.getImage({key: key, name: this.uploadedFile.name}))
      // this.items[_.findIndex(this.items, {id:this.item_id})].item_image_key = key
      this.showSuccessMessage('Photo updated successfully')
      this.buttonLoading = false
      this.$refs.table.refresh()
      this.showFileUploadModal = false


      // setTimeout (() => {
      //   const new_src = `${this.api_url}/items/${this.item_id}/image?${Date.now()}`
      //   var image = document.getElementById(`${this.item_id}_img`);
      //   image.src = new_src
      //   this.item_id = null
      //   this.popupActiveImage = false
      // },500)
    },

  }
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
